import { styled } from 'styled-components';
import React, {
  ComponentType,
  DetailedHTMLProps,
  FC,
  TextareaHTMLAttributes,
  useState,
} from 'react';
import { Styling } from '@/styling';

type State = {
  disabled?: boolean;
  valid?: boolean;
  focus?: boolean;
};

const RoundTextAreaBase = styled.div<State>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme, disabled }) =>
    disabled ? 'rgba(255, 255, 255, 0.5)' : theme.palette.grayscale.white};
  border-radius: ${Styling.radii('input')};
  border: ${({ theme, focus, valid }) => {
    if (focus) {
      return 'none';
    }

    if (valid === true) {
      return `2px solid ${theme.palette.success.main} !important`;
    } else if (valid === false) {
      return `2px solid ${theme.palette.error.main} !important`;
    } else {
      return 'none';
    }
  }};
  outline: ${({ theme, focus }) =>
    focus ? `1px solid ${theme.palette.primary.main}` : 0};

  &:hover {
    outline: ${({ theme, focus }) =>
      focus ? undefined : `1px solid ${theme.palette.grayscale.light2}`};
  }
`;

const UnderlinedTextAreaBase = styled.div<State>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grayscale.light5 : theme.palette.grayscale.white};
  border-top-left-radius: ${Styling.radii('input')};
  border-top-right-radius: ${Styling.radii('input')};
  border-bottom: ${({ theme, valid }) => {
    if (valid === true) {
      return `2px solid ${theme.palette.success.main} !important`;
    } else if (valid === false) {
      return `2px solid ${theme.palette.error.main} !important`;
    } else {
      return `2px solid ${theme.palette.grayscale.light5}`;
    }
  }};

  &:hover {
    border-bottom: ${({ theme, disabled }) =>
      disabled ? undefined : `2px solid ${theme.palette.grayscale.light4}`};
  }
`;

const FilledTextAreaBase = styled.div<State>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme }) => theme.palette.grayscale.light5};
  border-radius: ${Styling.radii('input')};
  border: 1px solid #e9e9e9; // TODO move to theme
  outline: ${({ theme, focus }) =>
    focus ? `1px solid ${theme.palette.primary.main}` : 0};
  transition:
    100ms background-color,
    100ms border;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '#F9FAFB' : theme.palette.grayscale.light5};
    outline: ${({ theme, focus }) =>
      focus ? undefined : `1px solid ${theme.palette.grayscale.light2}`};
  }
`;

const TextAreaInput = styled.textarea`
  ${Styling.typography('input')}
  padding: ${Styling.spacing(2)};
  padding-left: ${Styling.spacing(2)};
  width: 100% !important;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: inherit;

  &:disabled {
    cursor: not-allowed;
  }
`;

export type TextAreaProps = {
  className?: string;
  valid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | ComponentType<any>;
} & DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export const RoundTextArea: FC<TextAreaProps> = ({
  className,
  valid,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <RoundTextAreaBase
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      <TextAreaInput
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
    </RoundTextAreaBase>
  );
};

type UnderlineProps = {
  visible?: boolean;
};

const Underline = styled.div<UnderlineProps>`
  width: ${({ visible }) => (visible ? '100%' : '0%')};
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.palette.primary.main};
  transition: width 120ms;
`;

export const UnderlinedTextArea: FC<TextAreaProps> = ({
  className,
  valid,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <UnderlinedTextAreaBase
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      <TextAreaInput
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
      <Underline visible={hasFocus} />
    </UnderlinedTextAreaBase>
  );
};

export const FilledTextArea: FC<TextAreaProps> = ({
  className,
  valid,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <FilledTextAreaBase
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      <TextAreaInput
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
    </FilledTextAreaBase>
  );
};
