import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { minExecutionTime, Sentry } from '@idk-web/core-utils';
import {
  useAsync,
  AsyncState,
  useForceRerender,
  Styling,
  Box,
  useDialog,
  ErrorDialog,
  OkButton,
} from '@idk-web/core-ui';
import {
  getInboxMessages,
  getInboxMessageSigners,
  InboxMessage,
  InboxSigners,
  markInboxMessageAsRead,
} from '@idk-web/api';
import { translateError } from '@/utils/error';
import InboxReplyCard from '@/components/inbox/safemail/InboxReplyCard';
import InboxMessageCard from '@/components/inbox/safemail/InboxMessageCard';
import InboxCard from '@/components/inbox/safemail/InboxCard';

export type InboxMessageWithSigners = InboxMessage & Partial<InboxSigners>;

async function getInboxMessagesWithSigners(
  signal?: AbortSignal,
): Promise<InboxMessageWithSigners[]> {
  const messages = await getInboxMessages(signal);

  return Promise.all(
    messages.map(async (message) => {
      if (message.requestSignature) {
        const signers = await getInboxMessageSigners(message.id, signal);

        return { ...message, ...signers };
      }

      return message;
    }),
  );
}

const Container = styled(Box).attrs({
  direction: 'vertical',
  alignX: 'center',
})`
  width: 100%;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[5],
}))`
  width: 100%;
  padding: ${Styling.spacing(8)};
  @media (min-width: ${Styling.breakpoint('xl')}px) {
    max-width: 1400px;
  }
  @media (max-width: ${Styling.breakpoint('xl')}px) {
    width: 100%;
    padding: ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    width: 100%;
    padding: ${Styling.spacing(1)};
  }
`;

const delayedGetInboxMessagesWithSigners = minExecutionTime(
  getInboxMessagesWithSigners,
  500,
);

const SafeMailInbox: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const forceRerender = useForceRerender();
  const allMessages = useAsync(delayedGetInboxMessagesWithSigners, []);
  const [selection, setSelection] = useState<InboxMessageWithSigners | null>(
    null,
  );
  const [isReplying, setIsReplying] = useState<boolean>(false);

  useEffect(() => {
    if (allMessages.state === AsyncState.ERROR) {
      Sentry.reportError('End user inbox fetch request failed', {
        error: allMessages.error,
      });
      dialog.show((props) => (
        <ErrorDialog {...props} text={translateError(t, allMessages.error)} />
      ));
    }
  }, [allMessages]);

  useEffect(() => {
    if (allMessages.state === AsyncState.SUCCESS) {
      if (selection) {
        setSelection(
          allMessages.value.find((m) => m.id === selection.id) ?? null,
        );
      } else {
        setSelection(allMessages.value[0] ?? null);
      }
    }
  }, [allMessages]);

  useEffect(() => {
    if (selection && !selection.wasRead) {
      (async () => {
        try {
          await markInboxMessageAsRead(selection.id);
          selection.wasRead = true;
          forceRerender();
        } catch (e) {
          Sentry.reportError('Failed to mark end user inbox message as read', {
            error: e,
          });
        }
      })();
    }
  }, [selection]);

  useEffect(() => {
    setIsReplying(false);
  }, [selection]);

  const handleSelect = (message: InboxMessageWithSigners | null) =>
    setSelection(message);

  const loading = allMessages.state === AsyncState.PENDING;
  const messages = allMessages.value ?? [];

  return (
    <Container>
      <Content>
        {selection && (
          <InboxMessageCard
            message={selection}
            onSign={() => allMessages.reload()}
            onForward={() => allMessages.reload()}
            onDelete={() => allMessages.reload()}
          />
        )}
        {selection?.canReply && (
          <>
            {isReplying ? (
              <InboxReplyCard
                message={selection}
                onReply={() => setIsReplying(false)}
              />
            ) : (
              <Box direction="vertical" alignX="center">
                <OkButton
                  onClick={() => setIsReplying(true)}
                  text={t('inbox.safemail.reply.button')}
                />
              </Box>
            )}
          </>
        )}
        <InboxCard
          loading={loading}
          messages={messages}
          selection={selection ?? undefined}
          onSelect={handleSelect}
          onDelete={() => allMessages.reload()}
        />
      </Content>
    </Container>
  );
};

export default SafeMailInbox;
