import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Sentry } from '@idk-web/core-utils';
import {
  loseFocus,
  Styling,
  StandardCard,
  Box,
  useNotification,
  useDialog,
  ErrorDialog,
  FilledTextArea,
  SecureButton,
} from '@idk-web/core-ui';
import { replyToInboxMessage } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import { InboxMessageWithSigners } from '@/components/inbox/safemail/SafeMailInbox';
import { FormField } from '@/components/common/input/FormField';

const Form = styled.form``;

const Card = styled(StandardCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${Styling.spacing(6)};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(6, 4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(4, 2)};
  }
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[4],
  alignX: 'center',
}))``;

const SubmitButton = styled(SecureButton).attrs({
  type: 'submit',
})``;

export type InboxReplyCardProps = {
  message: InboxMessageWithSigners;
  onReply?(): void;
};

const InboxReplyCard: FC<InboxReplyCardProps> = ({ message, onReply }) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);
  const replyFormik = useFormik({
    initialValues: {
      body: '',
    },
    validationSchema: Yup.object().shape({
      body: Yup.string().required(
        t('errors.field_is_required', { field: t('common.text') }),
      ),
    }),
    async onSubmit({ body }) {
      loseFocus();

      setLoading(true);

      try {
        await replyToInboxMessage(message.id, { body });

        notification.show(
          t('inbox.safemail.reply.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );

        onReply?.();
      } catch (e) {
        Sentry.reportError('Failed to reply to inbox message', {
          error: e,
        });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }

      setLoading(false);
    },
  });

  return (
    <FormikProvider value={replyFormik}>
      <Form onSubmit={replyFormik.handleSubmit}>
        <Card>
          <Content>
            <FormField
              name="body"
              render={(props) => (
                <FilledTextArea
                  {...props}
                  disabled={loading}
                  placeholder={t('inbox.safemail.reply.placeholder')}
                />
              )}
            />
            <SubmitButton
              text={t('inbox.safemail.reply.button')}
              loading={loading}
              disabled={loading}
            />
          </Content>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default InboxReplyCard;
